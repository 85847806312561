<template>
    <div class="parameter">
        <div class="conter" v-html="description"></div>
        <Empty description="暂无数据" v-if="!description" />
        <!--<div class="hdpic">
            <VanImage width="100%" height="auto" lazy-load :src="require('@assets/images/test/test02.jpg')" />
        </div>
        <div class="parameter_list">
            <div :class="parameterh?'parameter_h':''">
                <Row gutter="5">
                    <Col span="8">
                        <div class="parameter_dd">
                            <span>品 牌:</span>
                            <div>塔鹿</div>
                        </div>
                    </Col>
                    <Col span="8">
                        <div class="parameter_dd">
                            <span>国 家:</span>
                            <div>智利</div>
                        </div>
                    </Col>
                    <Col span="8">
                        <div class="parameter_dd">
                            <span>酒 庄:</span>
                            <div>威亚</div>
                        </div>
                    </Col>
                    <Col span="8">
                        <div class="parameter_dd">
                            <span>类 型:</span>
                            <div>干红</div>
                        </div>
                    </Col>
                    <Col span="8">
                        <div class="parameter_dd">
                            <span>产 区:</span>
                            <div>中央山谷</div>
                        </div>
                    </Col>
                    <Col span="8">
                        <div class="parameter_dd">
                            <span>年 份:</span>
                            <div>2019</div>
                        </div>
                    </Col>
                    <Col span="8">
                        <div class="parameter_dd">
                            <span>级 别:</span>
                            <div>精选级</div>
                        </div>
                    </Col>
                    <Col span="8">
                        <div class="parameter_dd">
                            <span>采摘季节:</span>
                            <div>15%</div>
                        </div>
                    </Col>
                    <Col span="8">
                        <div class="parameter_dd">
                            <span>净含量:</span>
                            <div>750ml</div>
                        </div>
                    </Col>
                    <Col span="8">
                        <div class="parameter_dd">
                            <span>橡木桶:</span>
                            <div>6个月</div>
                        </div>
                    </Col>
                    <Col span="16">
                        <div class="parameter_dd">
                            <span>葡萄品种:</span>
                            <div>50%赤霞珠+50%赤霞珠</div>
                        </div>
                    </Col>
                    <Col span="24">
                        <div class="parameter_dd">
                            <span>色 泽:</span>
                            <div>拥有深峻的红中带紫般的色泽。88888888888888888888888888888888888888888888888888888888</div>
                        </div>
                    </Col>
                    <Col span="24">
                        <div class="parameter_dd">
                            <span>色 泽:</span>
                            <div>拥有深峻的红中带紫般的色泽。</div>
                        </div>
                    </Col>
                    <Col span="24">
                        <div class="parameter_dd">
                            <span>色 泽:</span>
                            <div>拥有深峻的红中带紫般的色泽。</div>
                        </div>
                    </Col>
                    <Col span="24">
                        <div class="parameter_dd">
                            <span>色 泽:</span>
                            <div>拥有深峻的红中带紫般的色泽。</div>
                        </div>
                    </Col>
                </Row>
            </div>
            
            <div class="parameter_icon" :class="parameterh?'parameter_180':''" @click="parametericon">
                <i class="iconfont iconxiala"></i>
            </div>
        </div>-->

        <!--实时口碑-->
        <!--<div class="koubei">
            <div class="hd acea-row row-between-wrapper">
                <div class="tit">实时口碑</div>
                <div>
                    <span>0人想喝</span>
                    <span>0人喝过</span>
                </div>
            </div>
            <div class="bd acea-row row-between-wrapper">
                <div class="pingfenlist">
                    <div class="progress-box">
                        <div class="percentage">100%</div>
                        <div class="progress">
                            <Progress :percentage="100" color="#f3b73f" stroke-width="0.18rem" :show-pivot="false" />
                        </div>
                        <Rate v-model="Rate.Rate5" :size="15" color="#6e667e" void-icon="star" void-color="#4a425a" readonly />
                    </div>
                    <div class="progress-box">
                        <div class="percentage">0%</div>
                        <div class="progress">
                            <Progress :percentage="0" color="#f3b73f" stroke-width="0.18rem" :show-pivot="false" />
                        </div>
                        <Rate v-model="Rate.Rate4" :size="15" color="#6e667e" void-icon="star" void-color="#4a425a" readonly />
                    </div>
                    <div class="progress-box">
                        <div class="percentage">0%</div>
                        <div class="progress">
                            <Progress :percentage="0" color="#f3b73f" stroke-width="0.18rem" :show-pivot="false" />
                        </div>
                        <Rate v-model="Rate.Rate3" :size="15" color="#6e667e" void-icon="star" void-color="#4a425a" readonly />
                    </div>
                    <div class="progress-box">
                        <div class="percentage">0%</div>
                        <div class="progress">
                            <Progress :percentage="0" color="#f3b73f" stroke-width="0.18rem" :show-pivot="false" />
                        </div>
                        <Rate v-model="Rate.Rate2" :size="15" color="#6e667e" void-icon="star" void-color="#4a425a" readonly />
                    </div>
                    <div class="progress-box">
                        <div class="percentage">0%</div>
                        <div class="progress">
                            <Progress :percentage="0" color="#f3b73f" stroke-width="0.18rem" :show-pivot="false" />
                        </div>
                        <Rate v-model="Rate.Rate1" :size="15" color="#6e667e" void-icon="star" void-color="#4a425a" readonly />
                    </div>
                </div>
                <div class="pingfen">
                    <div class="num">10</div>
                    <div>888 人评</div>
                </div>
            </div>
        </div>-->

        <!--专业级品鉴统计数据-->
        <!--<div class="count">
            <div class="hd acea-row row-between-wrapper">
                <div class="tit">专业级品鉴统计数据</div>
                <div>
                    全国<span class="font-color">1.2万条评论数据</span>
                </div>
            </div>
            <div class="count_tb">
                <Row>
                    <Col span="4">颜 色:</Col>
                    <Col span="4">中+</Col>
                    <Col span="4" class="cur-color">中58</Col>
                    <Col span="4">中48</Col>
                    <Col span="4">中00</Col>
                    <Col span="4">中00</Col>
                </Row>
                <Row>
                    <Col span="4">颜 色:</Col>
                    <Col span="4">中+</Col>
                    <Col span="4" class="cur-color">中58</Col>
                    <Col span="4">中48</Col>
                    <Col span="4">中00</Col>
                    <Col span="4">中00</Col>
                </Row>
                <Row>
                    <Col span="4">颜 色:</Col>
                    <Col span="4">中+</Col>
                    <Col span="4" class="cur-color">中58</Col>
                    <Col span="4">中48</Col>
                    <Col span="4">中00</Col>
                    <Col span="4">中00</Col>
                </Row>
                <Row>
                    <Col span="4">颜 色:</Col>
                    <Col span="4">中+</Col>
                    <Col span="4"></Col>
                    <Col span="4"></Col>
                    <Col span="4"></Col>
                    <Col span="4"></Col>
                </Row>
                <Row>
                    <Col span="4">颜 色:</Col>
                    <Col span="4">中+</Col>
                    <Col span="4"></Col>
                    <Col span="4"></Col>
                    <Col span="4"></Col>
                    <Col span="4"></Col>
                </Row>
                <Row>
                    <Col span="4">颜 色:</Col>
                    <Col span="4">中+</Col>
                    <Col span="4"></Col>
                    <Col span="4"></Col>
                    <Col span="4"></Col>
                    <Col span="4"></Col>
                </Row>
                <Row>
                    <Col span="4">颜 色:</Col>
                    <Col span="4">中+</Col>
                    <Col span="4"></Col>
                    <Col span="4"></Col>
                    <Col span="4"></Col>
                    <Col span="4"></Col>
                </Row>
            </div>
        </div>-->
        <!--杯酒论人生-->
        <!--<div class="comment">
            <div class="tit">
                <h1>杯酒论人生</h1>
                <div class="commentnav">
                    <a href="javascript:void(0);">全部1222</a>
                    <a href="javascript:void(0);">官方.活动22</a>
                    <a href="javascript:void(0);">喜宴.酒席211</a>
                    <a href="javascript:void(0);">商务.送礼21</a>
                    <a href="javascript:void(0);">自饮.配餐124</a>
                </div>
            </div>
            <div class="con">
                <div class="comment_item">
                    <div class="hd">
                        <div class="userpic">
                            <VanImage width="100%" height="100%" fit="cover" lazy-load src="" />
                        </div>
                        <div class="rgcon">
                            <div class="rghd acea-row row-between-wrapper">
                                <div>
                                    <div class="name Ellipses1">大圣999</div>
                                    <div class="grade">
                                        <span class="bg-color">酒徒</span>
                                    </div>
                                </div>
                                <div class="zhi">
                                    <div>
                                        <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                        <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                        <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                        <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                        <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                    </div>
                                    <div class="font-color">酒量值：220</div>
                                </div>
                            </div>
                            <div class="goodsname">十二星座-法国射手座</div>
                            <div class="pingfen">
                                <Rate v-model="2.5" allow-half :size="25" color="#b34550" void-icon="star" void-color="#eee" />
                            </div>
                            <div class="label">
                                <span>香气:较浓</span>
                                <span>酸度:酸</span>
                                <span>酒体:中高</span>
                                <span>单体:中高</span>
                            </div>
                            <div class="chosen Ellipses3">
                                <span class="font-color border-color">精选</span>
                                酒呈紫红色，淡淡的红色水果及橡木的香气，口感圆润，酒体柔软，易入口。
                            </div>
                            <div class="bpic">
                                <div class="bpic_dd">
                                    <VanImage width="100%" height="1.6rem" lazy-load src="" />
                                </div>
                                <div class="bpic_dd">
                                    <VanImage width="100%" height="1.6rem" lazy-load src="" />
                                </div>
                                <div class="bpic_dd">
                                    <VanImage width="100%" height="1.6rem" lazy-load src="" />
                                </div>
                            </div>
                            <div class="sharegoods">
                                <div class="sharegoods_pic">
                                    <VanImage width="100%" height="100%" fit="cover" lazy-load src="" />
                                </div>
                                <div class="sharegoods_con">
                                    <div class="dd Ellipses1">花溪酒庄三部曲红葡萄酒</div>
                                    <div class="dd Ellipses1">Flora Springs Trilogy</div>
                                    美国
                                </div>
                            </div>
                            <div class="rgfd acea-row row-between-wrapper">
                                <div>08-24 广州市</div>
                                <div class="rgfdicon">
                                    <Icon name="like-o">8</Icon>
                                    <Icon name="chat-o">8</Icon>
                                    <Icon name="contact">8</Icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="comment_item">
                    <div class="hd">
                        <div class="userpic">
                            <VanImage width="100%" height="100%" fit="cover" lazy-load src="" />
                        </div>
                        <div class="rgcon">
                            <div class="rghd acea-row row-between-wrapper">
                                <div>
                                    <div class="name Ellipses1">大圣999</div>
                                    <div class="grade">
                                        <span class="bg-color">酒徒</span>
                                    </div>
                                </div>
                                <div class="zhi">
                                    <div>
                                        <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                        <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                        <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                        <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                        <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                    </div>
                                    <div class="font-color">酒量值：220</div>
                                </div>
                            </div>
                            <div class="goodsname">十二星座-法国射手座</div>
                            <div class="pingfen">
                                <Rate v-model="2.5" allow-half :size="25" color="#b34550" void-icon="star" void-color="#eee" />
                            </div>
                            <div class="label">
                                <span>香气:较浓</span>
                                <span>酸度:酸</span>
                                <span>酒体:中高</span>
                                <span>单体:中高</span>
                            </div>
                            <div class="chosen Ellipses3">
                                <span class="font-color border-color">精选</span>
                                酒呈紫红色，淡淡的红色水果及橡木的香气，口感圆润，酒体柔软，易入口。
                            </div>
                            <div class="bpic">
                                <div class="bpic_dd">
                                    <VanImage width="100%" height="1.6rem" lazy-load src="" />
                                </div>
                                <div class="bpic_dd">
                                    <VanImage width="100%" height="1.6rem" lazy-load src="" />
                                </div>
                                <div class="bpic_dd">
                                    <VanImage width="100%" height="1.6rem" lazy-load src="" />
                                </div>
                            </div>
                            <div class="sharegoods">
                                <div class="sharegoods_pic">
                                    <VanImage width="100%" height="100%" fit="cover" lazy-load src="" />
                                </div>
                                <div class="sharegoods_con">
                                    <div class="dd Ellipses1">花溪酒庄三部曲红葡萄酒</div>
                                    <div class="dd Ellipses1">Flora Springs Trilogy</div>
                                    美国
                                </div>
                            </div>
                            <div class="rgfd acea-row row-between-wrapper">
                                <div>08-24 广州市</div>
                                <div class="rgfdicon">
                                    <Icon name="like-o">8</Icon>
                                    <Icon name="chat-o">8</Icon>
                                    <Icon name="contact">8</Icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="more font-color">
                    查看全部12346条分享 >>
                </div>
            </div>
        </div>-->

        <!--专业级品鉴-->
        <!--<div class="comment">
            <div class="tit">
                <h1>专业级品鉴</h1>
            </div>
            <div class="con">
                <div class="comment_item">
                    <div class="hd">
                        <div class="userpic">
                            <VanImage width="100%" height="100%" fit="cover" lazy-load src="" />
                        </div>
                        <div class="rgcon">
                            <div class="rghd acea-row row-between-wrapper">
                                <div>
                                    <div class="name Ellipses1">大圣999</div>
                                    <div class="grade">
                                        <span class="bg-color">酒徒</span>
                                    </div>
                                </div>
                                <div class="zhi">
                                    <div>
                                        <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                        <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                        <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                        <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                        <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                    </div>
                                    <div class="font-color">酒量值：220</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bd">
                        <div class="bdh1">塔鹿-彩鹿赤霞珠</div>
                        <div class="bdh2">智利 | 中央山谷 | 精选级 | 赤霞珠 | 13.5% | 2019</div>
                        <div class="count_tb">
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4" class="cur-color">中58</Col>
                                <Col span="4">中48</Col>
                                <Col span="4">中00</Col>
                                <Col span="4">中00</Col>
                            </Row>
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4" class="cur-color">中58</Col>
                                <Col span="4">中48</Col>
                                <Col span="4">中00</Col>
                                <Col span="4">中00</Col>
                            </Row>
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4" class="cur-color">中58</Col>
                                <Col span="4">中48</Col>
                                <Col span="4">中00</Col>
                                <Col span="4">中00</Col>
                            </Row>
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                            </Row>
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                            </Row>
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                            </Row>
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                            </Row>
                        </div>
                        <div class="bdh1">嗅觉-香气特征</div>
                        <div class="bdh3">
                            <span>一类香气：</span>
                            苺和桑椹果香为主，加上细微的薄荷巧克力气息...
                        </div>
                        <div class="bdh3">
                            <span>二类香气：</span>
                            苺和桑椹果香为主，加上细微的薄荷巧克力气息...
                        </div>
                        <div class="bdh3">
                            <span>三类香气：</span>
                            苺和桑椹果香为主，加上细微的薄荷巧克力气息...
                        </div>
                    </div>
                    <div class="fd acea-row row-between-wrapper">
                        <div>08-24 广州市</div>
                        <div class="rgfdicon">
                            <Icon name="like-o">8</Icon>
                            <Icon name="chat-o">8</Icon>
                            <Icon name="contact">8</Icon>
                        </div>
                    </div>
                </div>
                <div class="comment_item">
                    <div class="hd">
                        <div class="userpic">
                            <VanImage width="100%" height="100%" fit="cover" lazy-load src="" />
                        </div>
                        <div class="rgcon">
                            <div class="rghd acea-row row-between-wrapper">
                                <div>
                                    <div class="name Ellipses1">大圣999</div>
                                    <div class="grade">
                                        <span class="bg-color">酒徒</span>
                                    </div>
                                </div>
                                <div class="zhi">
                                    <div>
                                        <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                        <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                        <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                        <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                        <VanImage width="0.4rem" height="0.4rem" lazy-load :src="require('@assets/images/grade.png')" />
                                    </div>
                                    <div class="font-color">酒量值：220</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bd">
                        <div class="bdh1">塔鹿-彩鹿赤霞珠</div>
                        <div class="bdh2">智利 | 中央山谷 | 精选级 | 赤霞珠 | 13.5% | 2019</div>
                        <div class="count_tb">
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4" class="cur-color">中58</Col>
                                <Col span="4">中48</Col>
                                <Col span="4">中00</Col>
                                <Col span="4">中00</Col>
                            </Row>
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4" class="cur-color">中58</Col>
                                <Col span="4">中48</Col>
                                <Col span="4">中00</Col>
                                <Col span="4">中00</Col>
                            </Row>
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4" class="cur-color">中58</Col>
                                <Col span="4">中48</Col>
                                <Col span="4">中00</Col>
                                <Col span="4">中00</Col>
                            </Row>
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                            </Row>
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                            </Row>
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                            </Row>
                            <Row>
                                <Col span="4">颜 色:</Col>
                                <Col span="4">中+</Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                                <Col span="4"></Col>
                            </Row>
                        </div>
                        <div class="bdh1">嗅觉-香气特征</div>
                        <div class="bdh3">
                            <span>一类香气：</span>
                            苺和桑椹果香为主，加上细微的薄荷巧克力气息...
                        </div>
                        <div class="bdh3">
                            <span>二类香气：</span>
                            苺和桑椹果香为主，加上细微的薄荷巧克力气息...
                        </div>
                        <div class="bdh3">
                            <span>三类香气：</span>
                            苺和桑椹果香为主，加上细微的薄荷巧克力气息...
                        </div>
                    </div>
                    <div class="fd acea-row row-between-wrapper">
                        <div>08-24 广州市</div>
                        <div class="rgfdicon">
                            <Icon name="like-o">8</Icon>
                            <Icon name="chat-o">8</Icon>
                            <Icon name="contact">8</Icon>
                        </div>
                    </div>
                </div>
                <div class="more font-color">
                    查看全部12346条品鉴 >>
                </div>
            </div>
        </div>-->
    </div>
</template>
<script>
import { Col, Row, Empty, Rate, Progress, Icon } from 'vant';
import { Image as VanImage } from 'vant';
export default {
    name: "parameter",
    props: {
        description: {
            type: String,
            value: '',
        }
    },
    data: function() {
        return {
            parameterh: true,
            Rate: {
                Rate1: 1,
                Rate2: 2,
                Rate3: 3,
                Rate4: 4,
                Rate5: 5,
            }
        };
    },
    components: {
        VanImage, Col, Row, Empty, Rate, Progress, Icon
    },
    mounted: function() {},
    methods: {
        parametericon: function() {
            this.parameterh=!this.parameterh;
        },
    }
}
</script>
<style scoped lang="scss">
::v-deep {
    .conter{
        width: 100% !important;
        img{width: 100%!important;}
    }
    .van-rate__item:not(:last-child){padding-right: 0;}
    .van-icon-star{font-size: 0.3rem!important;}
}
.count_tb{
    border-top: 1px solid #eee;border-bottom: 1px solid #eee;background:#f3f3f3;
    .van-row{
        border-top: 1px solid #fff;
        .van-col{text-align: center;font-size:0.24rem;line-height: 0.5rem;border-left: 1px solid #fff;min-height: 0.5rem;}
        .van-col:first-child{background:#e8e8e8;border-left: none;}
        .cur-color{background: #ffd5d6;}
    }
    .van-row:first-child{border-top: none;}
}
.parameter{
    
    .parameter_list{
        padding: 0.2rem 0.2rem 0;font-size:0.24rem;
        .parameter_dd{
            margin-bottom:0.1rem;overflow:hidden;line-height: 0.3rem;display:flex;
            span{float:left;text-align: justify;min-width: 0.8rem;text-align-last: justify;}
            div{padding-left: 0.1rem;float:left;}
        }
        .parameter_h{height: 3rem;overflow:hidden;}
        .parameter_180{
            i{transform:rotate(180deg);display: block;}
        }
        .parameter_icon{
            text-align:center;line-height: 0.6rem;
        }
    }
    .koubei{
        background: #362c46;color: #8b8098;padding: 0.2rem;font-size: 0.2rem;
        .hd{
            .tit{color: #fff;font-size: 0.28rem;font-weight: bold;}
            span{margin-left: 0.1rem;}
        }
        .bd{
            padding: 0.2rem 0 0;display: flex;
            .pingfenlist{
                padding-right: 0.2rem;border-right: 1px solid #8b8098;width: 5rem;min-height:1.6rem;
                .progress-box{
                    display: flex;
                    .percentage{width: 0.8rem;text-align:right;padding-right: 0.1rem;}
                    .progress{flex: 1;padding-right: 0.1rem;padding-top: 0.05rem;}
                }
            }
            .pingfen{
                text-align: center;width: 2rem;
                .num{font-size: 0.6rem;text-align: center;color: #feb400;}
            }
        }
    }
    .count{
        .hd{
            padding: 0.2rem;color:#999;
            .tit{font-size:0.32rem;}
        }
    }
    .comment{
        margin-top: 0.4rem;background:#fff;border-top-left-radius: 0.2rem;border-top-right-radius: 0.2rem;
        .tit{
            border-bottom: 1px solid #eee;padding: 0.2rem 0.1rem;
            h1{text-align: center;font-size: 0.36rem;}
            .commentnav{
                font-size: 0.24rem;overflow:hidden;margin-top: 0.1rem;
                a{
                    display: inline-block;float: left;margin: 0.1rem;border: 1px solid #eee;line-height: 0.5rem;padding: 0 0.1rem;border-radius: 0.1rem;
                }
                
            }
        }
        .comment_item{
            border-bottom: 1px solid #eee;padding: 0.2rem;font-size: 0.24rem;
            .hd{
                overflow:hidden;
                .userpic{float:left;width:1rem;height:1rem;display:block;overflow:hidden;border-radius: 100%;}
                .rgcon{
                    padding-left: 1.2rem;color: #999;
                    .rghd{
                        height:1rem;
                        .name{max-width: 3rem;font-size: 0.28rem;color:#000;}
                        .grade{
                            margin-top: 0.05rem;
                            span{color:#fff;line-height: 0.4rem;display:block;float:left;padding: 0 0.3rem;border-radius:0.08rem;}
                        }
                        .zhi{text-align: right;}
                    }
                    .goodsname{font-size: 0.28rem;}
                    .pingfen{padding: 0.1rem 0;}
                    .label{
                        overflow:hidden;
                        span{display:block;float:left;margin-right:0.2rem;}
                    }
                    .chosen{
                        color:#333;line-height:0.4rem;padding: 0.1rem 0;
                        span{padding: 0.02rem 0.1rem;border-radius: 0.08rem;}
                    }
                    .bpic{
                        overflow:hidden;border-radius:0.4rem;
                        .bpic_dd{
                            width:33.333%;padding:0.05rem;float:left;
                            .van-image{}
                        }
                        .bpic_dd1{width:100%!important;}
                        .bpic_dd4{width:50%!important;}
                    }
                    .sharegoods{
                        background:#f3f3f5;padding: 0.1rem;overflow:hidden;
                        .sharegoods_pic{float:left;width: 1rem;height:1rem;}
                        .sharegoods_con{
                            padding-left: 1.2rem;padding-top:0.1rem;
                            .dd{line-height:0.3rem;color:#333;}
                        }
                    }
                    .rgfd{
                        padding-top: 0.1rem;
                        .rgfdicon{
                            .van-icon{float:left;margin-left: 0.15rem;}
                            .van-icon::before{font-size: 0.28rem;margin-right: 0.05rem;float:left;}
                        }
                    }
                }
            }
            .bd{
                .bdh1{font-size:0.28rem;color:#333;line-height: 0.5rem;}
                .bdh2{color: #999;margin-bottom:0.2rem;}
                .bdh3{
                    color: #999;margin-bottom:0.1rem;
                    span{color:#333;}
                }
            }
            .fd{
                padding-top: 0.1rem;
                .rgfdicon{
                    .van-icon{float:left;margin-left: 0.15rem;}
                    .van-icon::before{font-size: 0.28rem;margin-right: 0.05rem;float:left;}
                }
            }
        }
        .more{text-align: center;line-height:0.8rem;}
    }
}
</style>