<template>
    <div class="Detail" v-if="id">
        <!-- <div class="infotab" ref="topInfo">
                <Tabs v-model="activeName2" title-active-color="#000" class="topFixed" @click="htmlLocation()">
                    <tab  title="参数" name="c" ></tab>
                    <tab  title="相关视频" name="b"></tab>
                    <tab  title="产品详情" name="a"></tab>
                    <tab  title="杯酒论人生" name="d" ></tab>
                </Tabs>
            </div> -->
         <div ref="bInfo">
             <VideoList :VideoList="VideoList" :poster="VideoListimg" :videourl="VideoListurl" :title="false"></VideoList>
             <div class="Loading" v-if="VideoList==[]">此产品暂无讲解视频~</div>
         </div>
         <div class="verification" v-if="verifyResult">
                <div class="fixd-box">
                    <div>
                        <img src="@assets/images/standard.png">
                    </div>
                    <div class="right">
                        <div style="color:#f00">您好！您查询的产品为官方正品</div>
                        <div>品牌：{{info.brand.brand_name}}</div>
                        <div>品名：{{info.abbreviation_name}}</div>
                    </div>
                </div>
                <div class="chk-ver">
                    <h1>防伪验证成功：成功验证{{ver.queryCount}}次</h1>
                    <div class="mini-font">动态验证码/{{chk}}</div>
                    <div class="mini-font hui-font">查询者所在地{{ver.location}}</div>
                </div>
            </div>
            <div class="verification" v-if="chk&&chk!='undefined'&&!verifyResult">
                防伪验证失败，请稍后重试！
            </div>
        <div class="branditem" ref="cInfo">
            <div class="branditem_bd">
                <Swipe :initial-swipe="current" :duration="500" :autoplay="5000" :loop="false" :show-indicators="true" indicator-color="#e93323">
                    <SwipeItem v-for="(item, index) in SwipeList" :key="index">
                        <div class="Swipecon" v-if="index==0">
                            <div class="tit">
                                <p>{{info.en_name}}</p>
                                <p>{{info.store_name}}</p>
                            </div>
                            <div class="con" v-if="info">
                                <!-- 临时用 -->
                                <div v-if="info.cate_id.indexOf('33')!=-1">
                                    <p>
                                        <span class="greybg">类型</span>
                                        <a  class="greybg">：</a>
                                        <span>{{info.winelever.brand_name}}</span>
                                    </p>
                                    <p>
                                        <span class="greybg">产地</span>
                                        <a  class="greybg">：</a>
                                        <span>{{info.countries.brand_name}}</span>
                                    </p>
                                    <p>
                                        <span class="greybg">采摘季节</span>
                                        <a  class="greybg">：</a>
                                        <span>{{info.vol.brand_name}}</span>
                                    </p>
                                    <p>
                                        <span class="greybg">净含量</span>
                                        <a  class="greybg">：</a>
                                        <span>{{info.netweight.brand_name}}</span>
                                    </p>
                                    <p>
                                        <span class="greybg">质量等级</span>
                                        <a  class="greybg">：</a>
                                        <span>优级</span>
                                    </p>
                                    <p>
                                        <span class="greybg">生产日期</span>
                                        <a  class="greybg">：</a>
                                        <span>见瓶身</span>
                                    </p>
                                    <p>
                                        <span class="greybg">原料</span>
                                        <a  class="greybg">：</a>
                                        <span v-for="(item, index) in info.grape">
                                            {{item.brand_name}}、
                                        </span>
                                    </p>
                                    <p>
                                        <span class="greybg">贮存条件</span>
                                        <a  class="greybg">：</a>
                                        <span>干燥，常温下保存</span>
                                    </p>
                                     <p>
                                        <span class="greybg">执行标准</span>
                                        <a  class="greybg">：</a>
                                        <span>{{info.standardcode}}</span>
                                    </p>
                                    <p>
                                        <span class="greybg">生产许可证</span>
                                        <a  class="greybg">：</a>
                                        <span>{{info.qcode}}</span>
                                    </p>                                   
                                    <p>
                                        <span class="greybg">条形码</span>
                                        <a  class="greybg">：</a>
                                        <span>{{info.bar_code}}</span>
                                    </p>
                                    <p>
                                        <span class="greybg">温馨提示</span>
                                        <a  class="greybg">：</a>
                                        <span>过量饮酒，有害健康</span>
                                    </p>
                                </div>
                                <div v-else>
                                    <p>
                                        <span>国&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;家：</span>
                                        <span>{{info.countries.country}} {{info.chateau.english_name}}</span>
                                    </p>
                                    <p>
                                        <span>酒&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;庄：</span>
                                        <span>{{info.chateau.brand_name}}（{{info.chateau.history}}）</span>
                                    </p>
                                    <p>
                                        <span>品&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;牌：</span>
                                        <span>{{info.brand.brand_name}}</span>
                                    </p>
                                    <p>
                                        <span>产&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;区：</span>
                                        <span>{{info.countries.brand_name}}</span>
                                    </p>
                                    <p>
                                        <span>年&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;份：</span>
                                        <span>{{info.year.brand_name}}年</span>
                                    </p>
                                    <p>
                                        <span>级&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别：</span>
                                        <span>{{info.winelever.brand_name}}</span>
                                    </p>
                                    <p>
                                        <span>品&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;种：</span>
                                        <span v-for="(item, index) in info.grape">
                                            {{item.brand_name}}、
                                        </span>
                                    </p>
                                    <p>
                                        <span>酒&nbsp;精&nbsp;度：</span>
                                        <span>{{info.vol.brand_name}}%</span>
                                    </p>
                                    <p>
                                        <span>熟&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;化：</span>
                                        <span>{{info.aging.brand_name}}</span>
                                    </p>
                                    <p>
                                        <span>容&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;量：</span>
                                        <span>{{info.netweight.brand_name}}</span>
                                    </p>
                                    <p>
                                        <span>食物搭配：</span>
                                        <span v-for="(item, index) in info.collocation">
                                            {{item.brand_name}}、
                                        </span>
                                    </p>
                                    <div class="aroma">
                                        <div class="aromapic">
                                            <VanImage width="100%" height="100%" lazy-load :src="require('@assets/images/goods/aroma.jpg')" />
                                        </div>
                                        <div class="aromap">
                                        {{info.aroma}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cons" v-if="info.cate_id.indexOf('33')!=-1">
                                <div class="conBom">
                                    <p>
                                        <span class="greybg">委托方</span>
                                        <a  class="greybg">：</a>
                                        <span>{{info.entrust}}</span>
                                    </p>
                                    <!-- <p>
                                        <span class="greybg">生产方</span>
                                        <a  class="greybg">：</a>
                                        <span>{{info.produce}}</span>
                                    </p> -->
                                    <p>
                                        <span class="greybg">地址</span>
                                        <a  class="greybg">：</a>
                                        <span>{{info.countries.brand_name}}</span>
                                    </p>
                                    <p>
                                        <span class="greybg">联系电话</span>
                                        <a  class="greybg">：</a>
                                        <span>{{info.phone}}</span>
                                    </p>
                                </div>
                            </div>
                            <div v-if="info.prize">
                                <div class="Awarded" v-if="info.prize.length">
                                    <div class="Awardedpic">
                                        <VanImage width="100%" height="100%" lazy-load fit="cover" :src="require('@assets/images/goods/Awardedtit.png')" />
                                    </div>
                                    <div class="Awardedlist">
                                        <div class="Awardeditem" v-for="(item, index) in info.prize">
                                            <VanImage width="100%" height="100%" lazy-load fit="cover" :src="item.image" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="dpic">
                                <VanImage width="100%" height="100%" lazy-load fit="cover" :src="info.wcj_images" />
                            </div>
                        </div>
                        <div class="Swipepic" v-else>
                            <VanImage width="100%" height="100%" lazy-load fit="cover" :src="item.url" />
                        </div>
                    </SwipeItem>
                </Swipe>
            </div>
        </div>
        <div class="canshu">
            <div class="item" v-for="(item, index) in mao" :key="index">
                <div class="tit">{{item.name}}</div>
                <div  v-for="(sonitem, sonindex) in item.list" :key="sonindex"><span>{{sonitem}}</span></div>
            </div>
        </div>
        <GoodsList :goodsList="info.goods"></GoodsList>
         
        <div v-if="info.chateau">
            <div class="Winery" v-if="info.chateau.image_bg">
                <VanImage width="100%" height="100%" lazy-load fit="cover" :src="info.chateau.image_bg" @click="goPages('/Tasting/goods/Winery?id=' + id)" />
            </div>
        </div>
        <div style="height:1.4rem"></div>
        <div class="go">
                <div class="goMini" @click.stop="goPages()">点击进入官方查看</div>
        </div>
    </div>
    <div class="Detail" v-else>
        <div class="Loading">暂未绑定产品~</div>
    </div>
</template>
<script>
    import BrandList from "../components/brandList";
    import Backtop from "../components/Backtop";
    import GoodsList from "./components/goodsList";
    import VideoList from "./components/VideoList";
    import NewsList from "./components/NewsList";
    import Parameter from "./components/parameter";
    import Winery from "./components/winery";
    import Picture from "./components/picture";
    import { isWeixin_temp } from "@utils/index";
    import {getPoster, ProduceComplex, ProductVideo, getmenu, getUrlscheme,getchk } from "@api/tasting";
    import { Icon, Toast, Tab, Tabs, Swipe, SwipeItem, ImagePreview, Rate} from "vant";
    import { Image as VanImage } from 'vant';
    export default {
        name: "Detail",
        data () {
            return {
                id: '',
                token:'',
                activeName: '',
                //brandList: [],
                //goodsList: [], //单瓶列表
                goodsList2: [
                    'http://www.tuoers.cn/uploads/attach/2020/10/20201022/9431eccd0a45c4e42ad1609b90194758.png',
                    'http://store.oupuhui.com/uploads/attach/2020/09/20200908/aeb1d6e66bba25ba05d87863de188a0b.jpg',
                ], //商品列表
                //current: 0,
                SwipeList: [0],
                Slide: [],
                swiperOption: {
                    loop: true, //是否循环
                },
                info: '',
                VideoList: [],
                VideoListurl: '',
                VideoListimg: '',
                activeName2: 'c',
                activeShow:false,
                store_info: '',
                info2: '',
                list: [],
                topFixed:'offFixed',
                store_id:0,
                //offFixed:false,
                mapurl:'',
                apos:'',
                bpos:'',
                cpos:'',
                dpos:'',
                url:'Tasting/goods/detail',
                miniurl:'pages/scene/index',
                link:'',//urlscheme

                ver:[],
                verifyResult:false,
            }
        },
        components: {
            Icon, BrandList, VanImage, Backtop, Tab, Tabs, GoodsList, VideoList, NewsList, Parameter, Winery, Picture,Swipe, SwipeItem, ImagePreview, Rate 
        },
        created() {
            this.id=this.$route.query.id==undefined?0:this.$route.query.id;
            this.store_id=this.$route.query.store_id==undefined||this.$route.query.store_id==''?562:this.$route.query.store_id;

            this.chk = this.$route.query.chk;//防伪nfc标签
            this.pcode = this.$route.query.pcode!=undefined?this.$route.query.pcode:0;
        },
        mounted: function() {
            this.getscheme();
            this.getchk();
            this.getProduceComplex();
            this.setTopFixed();
        },
        methods: {
            getchk:function(){
                if(this.chk=="undefined"||this.chk==""){
                    this.chk=="";
                    return;
                }
                if(!isWeixin_temp()){
                    getchk({
                        chk:this.chk,
                        pcode:this.pcode,
                    }).then(res=>{
                        this.ver = res.data;
                        this.verifyResult = res.data.verifyResult;
                        // if(_data.verifyResult){
                        //     alert('防伪验证成功！已被验证了'+_data.queryCount+'次'+'!');
                        // }else{
                        //     alert('验证失败');
                        // }
                        console.log(res);
                    })
                }
            },
            setShow:function(){
                this.show = !this.show;
            },
            setShowp:function(){
                this.show2 = !this.show2;
            },
            setShowPoster:function(){
                this.show3 = !this.show3;
            },
            getPosters:function(code){
                getPoster({
                    product_id:this.$route.query.id
                }).then(res=>{
                    if(res.data!=''){
                        this.popup.data = res.data;
                        this.show3 = true;
                        this.showCode = 1;
                    }
                    this.SetPopup(code);
                })
            },
            setTopFixed:function(){
                var menu = this.$refs.topInfo;
                var aa = this.$refs.aInfo;//详情
                var bb = this.$refs.bInfo;//视频
                var cc = this.$refs.cInfo;//参数
                var dd = this.$refs.dInfo;//杯酒
                var _this  = this;
                window.addEventListener("scroll", () => {
                    var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                    menu.style.opacity = scrollTop/200;
                    if(scrollTop==0){
                        menu.style.display = 'none';
                    }else if(scrollTop>50){
                        menu.style.display = 'block';
                    }
                    if(menu.style.display == 'block'){
                        _this.activeShow=true;
                    }
                    //if(this.Suspension){return;}
                     _this.cpos = cc.getBoundingClientRect().top;
                    if (_this.cpos <= 0 && _this.cpos>=(-cc.offsetHeight)) {
                        _this.activeName2 = 'c';
                    }
                     _this.bpos = bb.getBoundingClientRect().top;
                    if (_this.bpos-84 <= 0 && _this.bpos>=(-bb.offsetHeight)) {
                        _this.activeName2 = 'b';
                    }
                     _this.dpos= dd.getBoundingClientRect().top;
                    if (_this.dpos-84 <= 0 && _this.dpos>=(-dd.offsetHeight)) {
                        _this.activeName2 = 'd';
                    }
                     _this.apos = aa.getBoundingClientRect().top;
                    if ( _this.apos-84 <= 0 &&  _this.apos>=(-aa.offsetHeight)) {
                        _this.activeName2 = 'a';
                    }

                });
            },
            setActiveShow:function(){
                this.activeShow =  this.activeShow?true:false;
            },
            goPages: function(url) {
                this.$router.push({ path: url });
            },
            //预览图片
            bindImagePreview: function(index, sonidnex) {
                ImagePreview({
                    images: this.list[index].images,
                    startPosition: sonidnex,
                });
            },
            getProduceComplex: function() {
                if(!this.id)return;
                Toast.loading({
                    duration: 0,
                    message: '加载中...',
                    forbidClick: true,
                });
                ProduceComplex({
                    id: this.id,
                    store_id:this.store_id
                }).then(res => {
                    Toast.clear();
                    this.activeName=res.data.product.brand.id;
                    this.info=res.data.product;
                    var mao=res.data.mao;
                    mao.forEach(function(item) {
                        var reg=/\\n/g;
                        item.value2=item.value.replace(reg,'/');
                        item.list=item.value2.split('/');
                    })
                    this.mao=mao;
                    this.getmenu(this.activeName);
                    var that=this;
                    let images=res.data.images;
                    images.forEach(function(item) {
                        that.SwipeList.push(item);
                    })

                     this.ProductVideo();
                })
                .catch(err => {
                    //console.log(err)
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            
            ProductVideo: function(id) {
                if(!this.id)return;
                ProductVideo({
                    id: this.id
                }).then(res => {
                    this.VideoList=res.data;
                    if(this.VideoList.length>0){
                        this.VideoListurl= this.VideoList[0].video;
                        this.VideoListimg= this.VideoList[0].images;
                    }
                })
                .catch(err => {
                    Toast.fail({
                        message: err.msg,
                        forbidClick: true,
                    });
                });
            },
            getmenu: function(id) {
                getmenu({
                    id:id,
                    level:3,
                }).then(res => {
                    this.goodsList=res.data;
                })
            },
        getscheme:function(){
            if(this.link)return;
            getUrlscheme({
                store_id : this.store_id,
                product_id : this.id,
                url : this.url,
                miniurl : this.miniurl,
            }).then(res=>{
                this.link = res.data.link;
            }).catch(err => {
                Toast.fail({
                    message: err.msg,
                    forbidClick: true,
                });
            });
        },
        goPages:function(){
            if(this.link==''){this.$dialog.error('链接不存在~');return}
            window.location.href = this.link;
            //this.$router.push({ path: this.link });
        },
            
        },
        watch:{
            $route(to,from){
                this.id=to.query.id;
                this.SwipeList=[0];
                this.getProduceComplex();
            },
            
        }
    }
</script>

<style scoped lang="scss">
// .infotab{
//     opacity: 0;
//     position: fixed;
//     z-index: 100;
//     width: 100%;
// }
.openMap{
    display: flex;
    justify-content:space-around;
    align-items:center;
    background-color: #fff;
    //padding: 0.2rem;
    margin-bottom: 0.2rem;
    //text-align: right;
    span{
        width: 50%;
         
    }
    .map-img{
        text-align: right;
    }
    .map-font{
        text-align: center;
    }
}
::v-deep{
        .infotab .van-tabs__line{
        transform: translateX(47px) translateX(-50%); 
        }
    }
.infotab{
    position: fixed;
    top: 0.8rem;
    z-index: 1008;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    opacity: 0;
    display: none;
}
::v-deep{
    // .van-rate{
    //     position:absolute;
    //     top:0.5rem;
    //     right: 0.2rem;
    // }
    .van-icon-star{font-size: 0.32rem!important;}
    .van-rate__item{padding-right: 0.05rem;}
}
::v-deep {
    .brandList{
        .van-tab{padding:0.3rem 0.1rem;}
        .van-tabs__wrap {
            height: 1.2rem;
        }
        .van-tabs__line{background:none;}
        .van-tabs__line::before{
            background:#f4f4f4;
            border-left: 1px solid #b6b6b6;
            border-top: 1px solid #b6b6b6;
            width: 0.2rem;
            height: 0.2rem;
            display: block;
            content: '';
            margin: 0 auto;transform: rotate(45deg);
        }
    }
    .van-swipe__indicator{width: 0.18rem;height:0.18rem;background-color:#a2a2a2;}
    .info{
        .van-tabs__line{background:#000;}
    }
}
.hd{
    img{width: 100%;}
}
.brandList{
    border-bottom:1px solid #eee;
    .brandpic{
        border: 1px solid #4c4744;border-radius: 0.1rem;overflow: hidden;height: 0.7rem;width: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        img{width: auto;height: 100%;}
    }
}
.branditem{
    background:#fff;overflow: hidden;
    .branditem_hd{
        height:1.2rem;background:#f4f4f4;margin-bottom: 0.8rem;position:relative;
        .pic{position:absolute;left: 0.2rem;width:1.82rem;height:1.6rem;border:1px solid #e8e8e8;overflow:hidden;border-radius:0.1rem;z-index:2;}
        .goodslist{
            position:absolute;right: 0;overflow-x: scroll;max-width:5.4rem;white-space: nowrap;z-index:2;
            .dpic{margin: 0.2rem 0.2rem 0;width: 0.4rem;display: inline-block;}
            .curdpic{margin: 0;width: 0.9rem;height: 1.7rem;border: 1px solid #cecece;padding: 0 0.2rem 0;display: inline-block;background: #f5f5f5;}
        }
        .picbg{position:absolute;left: 1.84rem;right: 0;z-index:1;}
    }
    .branditem_bd{
        position:relative;
        .van-swipe{height: 10.4rem;}
        .Swipepic{height: 10.4rem;width:100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;}
        .Swipecon{
            padding: 0.2rem;position:relative;
            .tit{
                background: #000;color:#fff;margin-top: 0.36rem;padding: 0.1rem 0.1rem 0.1rem 2.8rem;font-size: 0.24rem;
            }
            .greybg{
                   background: #d6d6d6; 
            }
            .con{
                margin-top: 0.1rem;padding: 0.1rem 0 0.1rem 2.7rem;font-size: 0.24rem;min-height: 6.6rem;
                p{
                    margin: 0.08rem 0;overflow:hidden;display: flex;
                    span{float:left;}
                    span:first-child{
                        //width: 1.6rem;text-align: right;
                        width: 1.5rem;
                        height: 0.34rem;
                        line-height: 0.34rem;
                        padding:0 0.1rem;
                        //text-align: right;
                        text-align: justify;
                        }
                    span:last-child{flex: 1;margin-left: 0.1rem;}
                    span:first-child::after {
                        display: inline-block;
                        width: 100%;
                        content: "";
                        }
                }
                
                .aroma{
                    border: 0.1rem solid #eee;line-height: 0.3rem;overflow: hidden;
                    .aromapic{
                        width: 1rem;height:1rem;float:left;
                    }
                    .aromap{padding: 0.1rem 0 0.1rem 1rem;}
                }
            }
            .cons{
                    border-bottom: 1px dashed #ccc;
                    border-top: 1px dashed #ccc;
                    position: relative;
                    z-index: 100;
                    padding: 0.1rem 0;
                    bottom: 0.5rem; 
                .conBom{
                    font-size: 0.24rem;
                    p{
                        margin: 0.06rem 0;overflow:hidden;display: flex;
                        span{float:left;}
                        span:first-child{
                        width: 1.4rem;
                        height: 0.34rem;
                        line-height: 0.34rem;
                        padding-left:0.1rem;
                        //text-align: right;
                        text-align: justify;
                        }
                        span:last-child{flex: 1;margin-left: 0.1rem;}
                        span:first-child::after {
                        display: inline-block;
                        width: 100%;
                        content: "";
                        }
                    }
                    
                }
            }
            
            .Awarded{
                overflow:hidden;
                .Awardedpic{float:left;width: 2.6rem;padding: 0.2rem 0;}
                .Awardedlist{
                    display: inline-block;overflow: scroll;float:left;width: 4.4rem;white-space:nowrap;
                    .Awardeditem{
                        display: inline-block;width: 0.8rem;height:0.8rem;margin: 0 0.02rem;
                    }
                }
            }
            .dpic{position:absolute;left:0.4rem;top:0;width: 2.4rem;height:8rem;background:#fff;}
        }
    }
}

.canshu{
    overflow-x: auto;background:#fff;padding:0.2rem 0;margin-top:0.2rem;
    .item {
        border-left: 1px solid #eee;padding: 0 0.3rem;white-space: nowrap;
        display: table-cell;
        span{font-size:0.2rem;color:#999;}
    }
    .item:first-child {border-left: none;}
}
.productInfo{
    background:#e8e8e8;
    .tit{
        border-bottom: 1px solid #eee;padding: 0.2rem 0.1rem;position:relative;
        h1{
            font-size: 0.36rem;
            color:#7b221e;
            padding-left: 0.3rem;
            span{
                font-size: 0.3rem;
                font-weight:200 ;
                vertical-align: bottom;
            }
        }
    }
}
.comment{
    margin-top: 0.4rem;background:#fff;
    //border-top-left-radius: 0.2rem;border-top-right-radius: 0.2rem;
    .tit{
        border-bottom: 1px solid #eee;padding: 0.2rem 0;position:relative;
        h1{text-align: center;font-size: 0.36rem;}
        .judge-img{
            margin-top:0.2rem;
        }
        .ed{position:absolute;top:0;right:0.2rem;line-height:0.9rem;}
        .commentnav{
            font-size: 0.24rem;overflow:hidden;margin-top: 0.1rem;
            a{
                display: inline-block;float: left;margin: 0.1rem;border: 1px solid #eee;line-height: 0.5rem;padding: 0 0.1rem;border-radius: 0.1rem;
            }
            
        }
    }
    .comment_item{
        position: relative;
        border-bottom: 1px solid #eee;padding: 0.2rem;font-size: 0.24rem;
        .hd{
            position:relative;
            .userpic{float:left;width:1rem;height:1rem;display:block;overflow:hidden;border-radius: 100%;}
            .rgcon{
                padding-left: 1.15rem;color: #999;
                height: 1.2rem;
                .fiex-row{
                    display: flex;
                }
                .lf{
                    width: 45%;
                    .time-city{
                        padding-top: 0.1rem;
                    }
                }
                .ri{
                    
                }
                .rghd{
                    height:1rem;
                    .name{max-width: 3rem;font-size: 0.24rem;color:#000;margin-top: 0.1rem;}
                    .grade{
                        margin-top: 0.05rem;
                        span{color:#fff;line-height: 0.4rem;display:block;float:left;padding: 0 0.05rem;border-radius:0.08rem;}
                        span:first-child{padding: 0 0.2rem;}
                        .zhi{
                            float:left;text-align: right;margin: 0 0.05rem 0 0.2rem;
                            .van-image{margin-left:-0.12rem;}
                        }
                    }
                    .zhi{text-align: right;}
                }
                .goodsname{font-size: 0.24rem;margin-top: 0.1rem;}
                .pingfen{padding: 0.1rem 0 ;}
                .label{
                    overflow:hidden;
                    span{display:block;float:left;margin-right:0.2rem;}
                }
                .chosen{
                    color:#333;line-height:0.4rem;padding: 0.1rem 0;font-size:0.28rem;
                    span{padding: 0.02rem 0.1rem;border-radius: 0.08rem;font-size:0.24rem;}
                }
                .bpic{
                    overflow:hidden;
                    .bpic_dd{
                        width:33.333%;padding:0.05rem;float:left;
                        .van-image{}
                    }
                    .bpic_dd1{width:100%!important;}
                    .bpic_dd4{width:50%!important;}
                }
                .sharegoods{
                    background:#f3f3f5;padding: 0.1rem;overflow:hidden;
                    .sharegoods_pic{float:left;width: 1rem;height:1rem;}
                    .sharegoods_con{
                        padding-left: 1.2rem;padding-top:0.1rem;
                        .dd{line-height:0.3rem;color:#333;}
                    }
                }
                .rgfd{
                    padding-top: 0.1rem;
                    .rgfdicon{
                        .van-icon{float:left;margin-left: 0.15rem;}
                        .van-icon::before{font-size: 0.28rem;margin-right: 0.05rem;float:left;}
                    }
                }
            }
            .add{
                position: absolute;top:0.24rem;right:0;display:block;font-size:0.24rem;padding:0.1rem;border-radius:0.1rem;border: 1px solid #999;color:#999;
                i{display: flex;}
                i::before{font-size:0.28rem;margin-right:0.05rem;}
            }
        }
        .bd{
            overflow:hidden;
            .userpic{float:left;width:1rem;height:1rem;display:block;overflow:hidden;border-radius: 100%;}
            .rgcon{
                //padding-left: 1.2rem;
                color: #999;
                .rghd{
                    height:1rem;
                    .name{max-width: 3rem;font-size: 0.28rem;color:#000;}
                    .grade{
                        margin-top: 0.05rem;
                        span{color:#fff;line-height: 0.4rem;display:block;float:left;padding: 0 0.3rem;border-radius:0.08rem;}
                    }
                    .zhi{text-align: right;}
                }
                // .goodsname{font-size: 0.28rem;padding-left: 1.2rem;

                // }
                .pingfen{
                    //padding: 0.1rem 0;
                    //margin-top: 0.3rem;float:right;margin-right: 0.2rem;
                    text-align: right;
                    }
                .label{
                    overflow:hidden;
                    span{display:block;float:left;margin-right:0.2rem;}
                }
                .chosen{
                    color: #525252;
                    line-height: 0.4rem;
                    padding-top: 0.1rem;
                    font-size: 0.3rem;
                    margin-bottom:0.1rem;
                    span{padding: 0.02rem 0.1rem;border-radius: 0.08rem;}
                }
                .bpic{
                    margin: 0.1rem 0;
                    overflow:hidden;
                    .bpic_dd{
                        width:100%;padding:0.05rem;float:left;
                        .van-image{}
                    }
                    .bpic_dd1{
                        width:100%!important;
                        .van-image{height:5rem!important;}
                    }
                    .bpic_dd3,.bpic_dd5,.bpic_dd6,.bpic_dd7,.bpic_dd8,.bpic_dd9{
                        width:33.33%!important;
                        .van-image{height:2rem!important;}
                    }
                    .bpic_dd4,.bpic_dd2{
                        width:50%!important;
                        .van-image{height:3rem!important;}
                    }
                }
                .sharegoods{
                    background:#f3f3f5;padding: 0.1rem;overflow:hidden;
                    .sharegoods_pic{float:left;width: 1rem;height:1rem;}
                    .sharegoods_con{
                        padding-left: 1.2rem;padding-top:0.1rem;
                        .dd{line-height:0.3rem;color:#333;}
                    }
                }
                .rgfdicon{
                        margin-right: 0.1rem;
                        .van-icon{float:right;margin-left: 0.15rem;}
                        .van-icon::before{font-size: 0.28rem;margin-right: 0.05rem;float:left;}
                    }
                .rgfd{
                    padding-top: 0.2rem;
                    
                }
            }
        }
    }
    .more{text-align: center;line-height:0.8rem;}
}

.Winery{
    margin-top: 0.2rem;min-height: 1rem;
}
.test-suspension{
    position: fixed;
    z-index: 5;
    right: 0;
    top: 50%;
    background: rgba(0, 0, 0, 0.534);
    padding: 0.1rem;
    writing-mode:tb-rl;
    color: #fff;
    border-radius: 0.1rem 0 0 0.1rem;
}
.Detail{
    .Loading{text-align: center;padding:0.2rem;font-size:0.28rem;color:#999;}
    .go{
        width: 100%;
        text-align: center;
        position: fixed;
        z-index: 4000;
        bottom: 0.5rem;
        .goMini{
            font-size: 0.3rem;
            color: #fff;
            background: #f00;
            padding: 0.2rem 0.6rem;
            border-radius: 0.4rem;
            display: inline-block;
        }
    }
    .verification{
        // z-index: 6666;
        // background: #0000002e;
        // color: #fff;
        // width: 100%;padding: 0.1rem;

        margin: 0.2rem 0.2rem 0.4rem 0.2rem;
        box-shadow: 0px 0px 9px #ccc;
        padding: 0.3rem;
        border-radius: 0.1rem;
        text-align: center;
        .mini-font{
            font-size: 0.2rem;
        }
        .hui-font{
            color: #999;
        }
        .fixd-box{
            display: flex;
            text-align: left;
            align-items:center;
            //font-size: 0.2rem;
            .right{
                margin-left: 0.5rem;
                div{
                    padding-bottom:0.04rem ;
                }
            }
            img{
                width: 1.5rem;
                height: 1.5rem;
            }
        }
        .chk-ver{
            border-top: 1px dashed #ccc;
            padding-top: 0.16rem;
        }
    }
}
</style>